*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
  padding: 0;
  overflow: hidden;
}
.swal2-container {
  z-index: 5000 !important;
}
.my-toast-class {
  position: absolute;
  z-index: 5000 !important; /* Desired z-index */
}

/* h1, p {
  color: black;
} */

/* pre {
  background-color: black !important;
  color: #1ac69c !important;
  padding: 30px;
  border-radius: 5px;
}
code {
  background: black  !important;
  color: #1ac69c !important;
  border-radius: 4px;
  padding: 4px;
} */

.crosshairs {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 99;
}

.crosshairs.hidden {
  display: none;
}


.crosshairs::before,
.crosshairs::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border: none !important;
  /* border-image: !important; */
}

.crosshairs::before {
  left: -100%;
  top: -100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.crosshairs::after {
  left: 0px;
  top: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.overlay {
  position: fixed;
  z-index: 1 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  
}

.overlay.highlighting {
  background: none;
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
}